import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSupportedCountriesAction, accessLoginAction } from "../../store/actions";
import { authActions, authSelector } from "../../store/slice/auth.slice";
import {
  Container,
  CountryImage,
  CountryListItem,
  CountryName,
  CountryPicker,
  LeftSection,
  RightSection,
  SubTitleText,
  TitleText,
  BlackRightArrowImg,
  BackButtonContainer,
  BackArrowImg,
  BackText
} from "./style.component";
import IconButton from '@mui/material/IconButton';
import { BlackRightArrow, IBackArrow } from "../../assets";
import { apiClient } from "../../api/api-client";

const ComplianceRoot = ({type,validationToken,onCountrySlection,supportedCountriesData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [supportedCountries, setSupportedCountries] = useState([]);


  const fetchCountries = useCallback(async () => {
    // await dispatch(getSupportedCountriesAction());
    if(supportedCountriesData?.length){
      setSupportedCountries(supportedCountriesData)}
  }, [supportedCountriesData]);

  console.log(validationToken, 'vt');
  console.log(type, 'type');

  const onCountryListItemClick = async (data) => {
    onCountrySlection(data)
  };

  const getCountryList = () => {
    if (supportedCountries?.length <= 0) return;
    return supportedCountries?.map((item, index) => {
      return (
        <CountryListItem
          key={`country_list_${index}`}
          onClick={() => onCountryListItemClick(item)}
        >
          <LeftSection>
            {/* <CountryImage src={item?.icon} alt={item?.display_name} /> */}
          </LeftSection>
          <RightSection>
            <CountryName>{item?.display_name}</CountryName>
          </RightSection>
          <IconButton>
            <BlackRightArrowImg src= { BlackRightArrow } />
          </IconButton>
        </CountryListItem>
      );
    });
  };

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleBackClick = () => {
    navigate("/login", { state: { loginMethod: type, redirectedFrom: "compliance", showComplianceRoot: false } });
  };

  return (
    <Container>
      <TitleText>We have found multiple accounts for you. Please select the country whose patients you want to access. For countries other than India and UK, select India.</TitleText>
      <CountryPicker>{getCountryList()}</CountryPicker>
    </Container>
  );
};

export default ComplianceRoot;