import { CircularProgress } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ILogoHorizontalWhite, IResend, PhysicianImage, BlackRightArrow, EmailIcon, MobileIcon, IBackArrow } from "../../assets";

import { generateOTPAction } from "../../store/actions";
import { COLOR_VARIANT } from "../../utilities/constants";
import IconButton from '@mui/material/IconButton';
import { SnackBar } from "../../components/shared";
import { getSupportedCountriesAction, verifyOTPAction, accessLoginAction } from "../../store/actions/auth.action";
import { authActions, authSelector } from "../../store/slice/auth.slice";
import {
  ActionText,
  CustomButton,
  CustomInput,
  CustomSelect,
  FormFieldRow,
  FormFieldSection,
  FormLabel,
  FormMessage,
  LoginContainer,
  LoginForm,
  LoginFormRight,
  LoginLogo,
  TCLink,
  TCText,
  LoginFormLeft,
  ImageBanner,
  TextDescription,
  CtaLink,
  WelcomeMessage,
  InfoContainer,
  IconTextContainer,
  LoginByContainer,
  LoginByText,
  BlackRightArrowImg,
  MobileIconImg,
  EmailIconImg,
  LoginFormContainer,
  BackButtonContainer,
  BackText,
  BackArrowImg
} from "./style.components";
import {apiClient} from "../../api/api-client";
import ComplianceRoot from "../compliance";
import { unsetShowStatus } from "../../store/slice/status-slice";

const Login = () => {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otpMobile, setOtpMobile] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSupportedCountry, setSelectedSupportedCountry] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showComplianceRoot, setShowComplianceRoot] = useState(false);
  const [type, setType] = useState("");
  const [validationToken, setValidationToken] = useState(""); 
  const [accessCountryCode, setAccessCountryCode] = useState("");
  const [supportedCountriesData, setSupportedCountriesData] = useState([]);
  
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, isOTPVerified, userDetails, selectedCountry, supportedCountries, datastores } = useSelector(authSelector);

  useEffect(() => {
    const search = location.search.replace('?patient-records?', '&');
    const queryParams = new URLSearchParams(search);
    const patientId = queryParams.get('patient_id');
    if (isOTPVerified && userDetails?.access_token) {
      // If the user is logged in and a patient_id is available, navigate to PatientRecords for that patient
      if (patientId) {
        navigate(`/patient-records?patient_id=${patientId}`);
      } else {
        navigate("/home"); // Navigate to home if there's no patient_id in the query params
      }
    } else if (!isOTPVerified && patientId) {
      // If the user is not logged in and a patient_id is provided, navigate to ComplianceRoot
      navigate(`/login?patient_id=${patientId}`);
    }
  }, [isOTPVerified, userDetails, navigate, searchParams]);

  const fetchCountries = useCallback(async () => {
    await dispatch(getSupportedCountriesAction());
  }, [dispatch]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    if (location.state && (location.state.loginMethod || location.state.showComplianceRoot)) {
      setType(location.state.loginMethod);
      setShowComplianceRoot(location.state.showComplianceRoot);
    }
  }, [location.state]);

  useEffect(() => {
    const phoneNumber = searchParams?.get("phone")?.trim();
    const email = searchParams?.get("email")?.trim();
    const country = searchParams?.get("country")?.trim();
    let inputNumber = "";
    if (phoneNumber) {
      setType('phone');
      setShowLoginForm(true);
      setShowComplianceRoot(false);
      if (["91", "44"].includes(phoneNumber.slice(0, 2).toString())) {
        const countryCode = phoneNumber.slice(0, 2).toString();
        setCountryCode(`+${countryCode}`);
        inputNumber = phoneNumber.slice(2, phoneNumber.length);
      } else if (["212"].includes(phoneNumber.slice(0, 3))) {
        setCountryCode(`+${phoneNumber.slice(0, 3).toString()}`);
        inputNumber = phoneNumber.slice(3, phoneNumber.length);
      }
      setMobileNumber(inputNumber);
      const _selectedSupportedCountry = supportedCountries?.filter(
        (c) => c?.code === country
      )?.[0];
      setSelectedSupportedCountry(_selectedSupportedCountry);
      // dispatch(authActions.selectedCountry(_selectedSupportedCountry));
    }
    if(email) {
      setType('email');
      setEmail(email);
      setShowLoginForm(true);
      setShowComplianceRoot(false);
    }
  }, [searchParams, supportedCountries, dispatch]);

  useEffect(() => {
    if (selectedCountry?.code) {
      let code;
      switch (selectedCountry?.code) {
        case "IN": {
          code = "+91";
          break;
        }
        case "GB": {
          code = "+44";
          break;
        }
        default:
          code = "+91";
      }
      setCountryCode(code);
    }
  }, [selectedCountry]);

  const sendOtpToMobileNumber = useCallback(async () => {
    let payload = {};
    if(mobileNumber && type === 'phone'){
      payload = {
        "auth_id": `${countryCode}${mobileNumber}`,
        "auth_type": "phone",
        "country": selectedCountry?.code || selectedSupportedCountry?.code,
        "source":"login"
      }
    }
    else if(email && type === 'email'){
      payload = {
        "auth_id": `${email}`,
        "auth_type": "email",
        "source": "login"
      }
    }
    const res = await dispatch(generateOTPAction(payload));
    if(res.payload.status === "success"){
      setIsOtpSent(true)
    }

  }, [dispatch, mobileNumber, email, selectedCountry, selectedSupportedCountry, countryCode, type]);

  const handleSendOtpClick = useCallback(() => {
    sendOtpToMobileNumber(type);
  }, [sendOtpToMobileNumber]);

const onCountrySlection=(data)=>{
  setAccessCountryCode(data.code)
    getAccessToken(data.code)
}

  const getAccessToken= async (code)=>{
    try {
      if(validationToken) {
        apiClient?.setAuthHeader(validationToken)
      } ;

      const loginPayload = {
        country: code // Ensure that `code` is the correct property you want to use
      };
      const response = await dispatch(accessLoginAction(loginPayload)).unwrap();
      if (response.status === 'success') {
        const patientId = searchParams.get("patient_id");
        patientId
          ? navigate(`/patient-records?patient_id=${patientId}`)
          : navigate("/home"); // Navigate to the home page on successful login
      } else {
        // Optionally handle unsuccessful login attempts
        console.error('Login failed:', response.message);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  }
  
  const validateOtp = useCallback(async () => {
    let payload = type === 'phone' ? {
      auth_id: `${countryCode}${mobileNumber}`,
      auth_type: "phone",
      country: selectedCountry?.code || selectedSupportedCountry?.code,
      source: "login",
      otp: otpMobile
    } : {
      auth_id: email,
      auth_type: "email",
      source: "login",
      otp: otpEmail
    };
  
    try {
      const response = await dispatch(verifyOTPAction(payload)).unwrap();
      if (response.status === 'success') {
        dispatch(unsetShowStatus());
        // Set the token in headers for subsequent requests
        apiClient?.setAuthHeader(response?.result?.validation_token);

        if(response.result.datastores.length > 1){
          setShowComplianceRoot(true);
          setShowLoginForm(false);
          setValidationToken(response.result?.validation_token);
          setSupportedCountriesData(response.result?.datastores);
        }else{
          getAccessToken(response.result?.datastores[0].code);
        }

       }
    } catch (error) {
      console.error('Verification failed:', error);
    }
  }, [dispatch, type, countryCode, mobileNumber, email, otpMobile, otpEmail]);  

  const handleLoginClick = useCallback(() => {
    // event.preventDefault();
    validateOtp(type);
  }, [validateOtp]);

  const handleLoginTypeSelection = (selectedType) => {
    setType(selectedType);
    if(selectedType === "email"){
      setShowLoginForm(true);
      setShowComplianceRoot(false);
    }else{
      setShowLoginForm(true);
      setShowComplianceRoot(false);
    }
   
  };

  const handleBackToLoginTypeSelection = () => {
    setShowLoginForm(false);
    setShowComplianceRoot(false);
    setIsOtpSent(false)
    setMobileNumber('')
    setEmail('')
  };

  return (
    <>
      <LoginContainer>
        <LoginLogo>
          <img src={ILogoHorizontalWhite} alt="logo" height={40}/>
        </LoginLogo>
        <LoginForm>
          <LoginFormLeft>
            <ImageBanner>
              <img height={128} src={PhysicianImage} />
              <WelcomeMessage>
                Welcome, <br />
                <span style={{ fontSize: "28px" }}>Physican</span>
              </WelcomeMessage>
            </ImageBanner>
            <TextDescription>
              AllMyHealth is an integrated health platform that allows
              physicians and carers to securely access patient information,
              following appropriate consent. It helps labs simplify their daily
              workflows like sending reports to patients, replacing reports on
              the go, viewing the historical reports and more.
            </TextDescription>
            <CtaLink target={"_blank"} href="https://allmyhealth.io/">
              Visit us at <b>allmyhealth.io</b>
            </CtaLink>
          </LoginFormLeft>
          <LoginFormRight>
            {showComplianceRoot ? (
              <LoginFormContainer>
                 <BackButtonContainer onClick={handleBackToLoginTypeSelection}>
                  <BackArrowImg src={IBackArrow} />
                  <BackText>{`Back to Login`}</BackText>
                </BackButtonContainer>
              <ComplianceRoot type= {type} validationToken= {validationToken} onCountrySlection={onCountrySlection} supportedCountriesData={supportedCountriesData}/>
              </LoginFormContainer>
            ) : showLoginForm ? (
              <LoginFormContainer>
                <BackButtonContainer onClick={handleBackToLoginTypeSelection}>
                  <BackArrowImg src={IBackArrow} />
                  <BackText>{`Back to Login`}</BackText>
                </BackButtonContainer>
              {!isOtpSent ? <>
                <FormFieldSection>
                    <FormLabel>{type === 'phone' ? 'Mobile no.' : 'E-mail'}</FormLabel>
                    <FormFieldRow>
                      {type === 'phone' && (
                        <>
                          <CustomSelect value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                            <option value="+91">+91</option>
                            <option value="+44">+44</option>
                            <option value="+212">+212</option>
                          </CustomSelect>
                          <CustomInput value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} type="number" onWheel={(e) => e.target.blur()} />
                        </>
                      )}
                      {type === 'email' && (
                        <CustomInput value={email} onChange={(e) => setEmail(e.target.value)} type="email" onWheel={(e) => e.target.blur()} />
                      )}
                    </FormFieldRow>

                    <CustomButton style={{margin:"40px auto"}} onClick={handleSendOtpClick} value="Send OTP" type="button" />

                  </FormFieldSection>
                  </>:
<>
                  <FormFieldSection>
                    <FormLabel>Enter OTP</FormLabel>
                    <FormFieldRow>
                      <CustomInput value={type === 'phone' ? otpMobile : otpEmail} onChange={(e) => type === 'phone' ? setOtpMobile(e.target.value) : setOtpEmail(e.target.value)} type="number" />
                      <ActionText onClick={handleSendOtpClick}>
                        <img src={IResend} style={{ marginRight: "4px" }} alt="resend" /> Resend
                      </ActionText>
                    </FormFieldRow>
                  </FormFieldSection>
                  <TCText>
                    By logging in you agree to our
                    <TCLink href="terms-conditions" target="_blank">Terms and Conditions</TCLink>
                  </TCText>
                  <FormFieldSection>
                    <CustomButton onClick={handleLoginClick} value="Login" type="button" />
                  </FormFieldSection>
</>}
              </LoginFormContainer>
            ) : (
              <LoginByContainer>
                <LoginByText>Login by</LoginByText>
                <InfoContainer onClick={() => handleLoginTypeSelection('phone')}>
                  <IconTextContainer>
                    <MobileIconImg src={MobileIcon} />
                    <span>Mobile Number</span>
                  </IconTextContainer>
                  <IconButton><BlackRightArrowImg src={BlackRightArrow} /></IconButton>
                </InfoContainer>
                <InfoContainer onClick={() => handleLoginTypeSelection('email')}>
                  <IconTextContainer>
                    <EmailIconImg src={EmailIcon} />
                    <span>E-mail</span>
                  </IconTextContainer>
                      <IconButton><BlackRightArrowImg src={BlackRightArrow} /></IconButton>
                </InfoContainer>
              </LoginByContainer>
            )}
          </LoginFormRight>
        </LoginForm>
      </LoginContainer>
      <SnackBar />
    </>
  );
};
export default Login;
