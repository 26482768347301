import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { formatTimestamp } from "../../utilities/common";
import styled from "styled-components";
import {
  fetchTeleRadiologyData,
  getTeleRadiologyData,
} from "../../api/tele-radiology.api";
import { useLocation, useNavigate } from "react-router-dom";
import StatusDropdown from "../shared/StatusDropdown";
import StatusDropDownStatus from "../shared/StatusDropDownStatus";
import ConfirmationBox from "../InfoSection/ConfirmationBox";

const HrLine = styled.div`
  border: 1px solid #c5dde0;
  margin: 1rem 0;
`;

const InfoName = styled.div`
  font-weight: 600;
  width: 200px;
  word-wrap: break-word; /* Allows breaking long words */
  overflow-wrap: break-word;
`;

const HeaderTitle = {
  color: { xs: "#808080", sm: "#000" },
};

const LabInfo = ({ recordInfo }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isShowChangeStatus, setShowChangeStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dropdownOptions = [
    { label: "Pending", value: "draft" },
    { label: "Completed", value: "completed" },
    { label: "Rejected", value: "revoked" },
  ];

  console.log(recordInfo);

  const getStatusStyles = (status) => {
    const styles = {
      draft: {
        sx: {
          backgroundColor: "#CC8552",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CC8552",
          },
        },
      },
      completed: {
        sx: {
          backgroundColor: "#7ABA56",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7ABA56",
          },
        },
      },
      revoked: {
        sx: {
          backgroundColor: "#808080",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#808080",
          },
        },
      },
      default: {
        sx: {
          backgroundColor: "#FFF",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CCC",
          },
        },
      },
    };
    return styles[status] || styles.default;
  };

  const postTeleRadiologyData = async (request_id, updatedData) => {
    console.log("updatedData", request_id, updatedData);
    await getTeleRadiologyData(request_id, updatedData);
    navigate("/tele-radiology");
  };

  const handleStatus = (request_id, data) => {
    isShowChangeStatus(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get day, month, year
    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");

    // Get hours, minutes, and format to 12-hour time with AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, with 12 instead of 0

    return `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;
  };

  return (
    <>
      <Box mt={1}>
        <Box sx={HeaderTitle}>Requested by / Lab Name</Box>
        {recordInfo ? (
          <InfoName>{recordInfo.performer_info}</InfoName>
        ) : (
          <p>Loading...</p> // Placeholder while data loads
        )}
      </Box>
      <HrLine />
      <Box mt={1}>
        <Box sx={HeaderTitle}>Priority</Box>
        {recordInfo ? (
          <Button
            sx={{
              color: "#000",
              border: "1px solid #FFF",
              borderRadius: "15px",
              padding: "5px 10px",
              backgroundColor:
                recordInfo.urgency === "urgent"
                  ? "#F5CECE"
                  : recordInfo.urgency === "Medium"
                  ? "#FFF5C2"
                  : recordInfo.urgency === "routine"
                  ? "#D7EFF7"
                  : "transparent",
            }}
          >
             {recordInfo.urgency.charAt(0).toUpperCase() + recordInfo.urgency.slice(1)}
          </Button>
        ) : (
          <p>Loading...</p> // Placeholder while data loads
        )}
      </Box>
      <HrLine />
      <Box mt={1}>
        <Box sx={HeaderTitle}>Requested at</Box>
        {recordInfo ? (
          <InfoName>{formatDate(recordInfo.request_date)}</InfoName>
        ) : (
          <p>Loading...</p> // Placeholder while data loads
        )}
      </Box>
      <HrLine />
      <Box mt={1}>
        <Box sx={HeaderTitle}>Comments</Box>
        {recordInfo ? (
          <InfoName>{recordInfo.comments}</InfoName>
        ) : (
          <p>Loading...</p> // Placeholder while data loads
        )}
      </Box>
      {location.pathname !== "/tele-radiology/scan-info/add-records" &&
      location.pathname !==
        "/tele-radiology/scan-info/add-records/send-records" ? (
        <>
          <HrLine />

          <Box sx={HeaderTitle}>Status</Box>
          <StatusDropDownStatus
            handleChange={(e) => {
              setSelectedRow({
                request_id: recordInfo.request_id,
                status: e.target.value,
              });
              setShowChangeStatus(true); // Open confirmation box
            }}
            selectedStatus={recordInfo?.status}
            dropdownOptions={dropdownOptions}
            showInfoText={false}
            sx={{
              ...getStatusStyles(recordInfo?.status || "").sx,
              width: "180px",
              height: "45px",
            }}
          />
          {isShowChangeStatus && (
            <ConfirmationBox
              title="Are you Sure?"
              description="Do you want to change the status of the request?"
              closeHandler={() => setShowChangeStatus(false)} // Close the confirmation box
              open={isShowChangeStatus}
              onConfirm={() => {
                postTeleRadiologyData(selectedRow.request_id, {
                  status: selectedRow.status,
                });
                setShowChangeStatus(false); // Close the confirmation box after confirming
              }}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default LabInfo;
