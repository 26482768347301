import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import RemoteSources from "@uppy/remote-sources";
import Transloadit from "@uppy/transloadit";
import Tus from "@uppy/tus";
import React, { useEffect } from "react";
import { authSelector } from "../../../store/slice/auth.slice";
import { useSelector } from "react-redux";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./UppyUploadBox.css";
import Compressor from "@uppy/compressor";
import DropTarget from "@uppy/drop-target";

const UPLOADER = "tus"; 
const TUS_ENDPOINT = "https://dev.uploader.hemehealth.app/files/";
const COMPANION_URL = "http://companion.uppy.io";

const UppyUploaderEncounter = ({ onUploadComplete, allowedFileTypes, uppyRef, setIsUppyReady }) => {
  const { selectedCountry } = useSelector(authSelector);

  useEffect(() => {
    const uppyDashboard = new Uppy({
      restrictions: {
        allowedFileTypes: allowedFileTypes.length > 0 ? allowedFileTypes : null,
      },
      autoProceed: false, 
    })
    .use(Dashboard, {
      inline: true,
      target: "#uppy",
      showProgressDetails: true,
      width: "100%",
      height: 350,
    })
    .use(RemoteSources, {
      companionUrl: COMPANION_URL,
      sources: [],
    })
    .use(Transloadit, {
      importFromUploadURLs: true,
      assemblyOptions: {
        params: {
          auth: { key: "ea5babaf8c8d4baeab822a7f48b55ad9" },
          template_id: selectedCountry?.code === "IN" ? "75562b983ac6460799bf7468bde65440" : "2bf84a23b1b348d480a0cdcca8096f3c",
        },
      },
      waitForEncoding: true,
    })
    .use(DropTarget, {
      target: document.body,
    })
    .use(Compressor);

    uppyDashboard.use(Tus, { endpoint: TUS_ENDPOINT, limit: 6 });
    if (uppyRef) {
      uppyRef.current = uppyDashboard;
      console.log("Uppy instance set:", uppyRef.current);
      setIsUppyReady(true);
    }

    uppyDashboard.on("transloadit:complete", (assembly) => {
      const uploadedFiles = assembly?.uploads?.map((upload) => ({
        file_id: upload.id,
        file_name: upload.name,
        mimetype: upload.mime,
        file_size: upload.size,
      }));

      if (onUploadComplete) {
        onUploadComplete({ assemblyID: assembly?.assembly_id, uploadedFiles });
      }
    });

    return () => {
      if (uppyDashboard) {
        uppyDashboard.cancelAll();
        uppyDashboard.close();
      }
    };
  }, []);

  return <div id="uppy" className="uppy-uploader-container"></div>;
};

export default UppyUploaderEncounter;
