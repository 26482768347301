import { Box, FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StatusDropdown = ({
  handleChange,
  selectedStatus,
  dropdownOptions = [
    { label: "Available", value: "AVAILABLE" },
    { label: "Restricted", value: "RESTRICTED" },
  ],
  disabled = false,
  showInfoText = true,
  sx = {},
}) => {
  return (
    <FormControl
      sx={{
        width: "100%", 
        minWidth: "100px",
        ...sx, // Apply dynamic styles
      }}
      disabled={disabled}
    >
      <Select
        value={selectedStatus}
        onChange={handleChange}
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          paddingRight: 1,
          height: "45px",
          backgroundColor: "#FFF",
          color: selectedStatus
            ? selectedStatus !== "RESTRICTED"
              ? "#7ABA56"
              : "#CC8552"
            : "#999",
          "& .MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${
              selectedStatus && selectedStatus !== "RESTRICTED"
                ? "#7ABA56"
                : "#CC8552"
            }`,
          },
          "& .MuiSvgIcon-root": {
            background:
              selectedStatus && selectedStatus !== "RESTRICTED"
                ? "#7ABA56"
                : "#CC8552",
            borderRadius: 1,
            color: "#FFF",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor:
              selectedStatus && selectedStatus !== "RESTRICTED"
                ? "#7ABA56"
                : "#CC8552",
          },
        }}
        renderValue={(value) => {
          if (!value) return "Select Status";
          return (
            dropdownOptions.find((option) => option.value === value)?.label ||
            value
          );
        }}
      >
        {dropdownOptions.map((option) => (
          <MenuItem key={`dropdown-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {showInfoText && (
        <Box sx={{ whiteSpace: "normal", fontSize: "11px", marginTop: "5px" }}>
          {selectedStatus !== "RESTRICTED"
            ? "(File will be available for the patient and others to view)"
            : "(Only available for clinicians or radiologists to view)"}
        </Box>
      )}
    </FormControl>
  );
};

export default StatusDropdown;
