import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MetaInfo from "../MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import "./InfoSection.css";
import {
  getStudyInfo,
  getScansDetails,
  getAttachedReport,
  getReportURL,
  changeStatusRestrictedToAvailableAction,
  getThumbnails,
} from "../../store/actions/records.action";
import { removeSpecialCharacters } from "../../utilities/common";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../layout/Breadcrumb";
import {
  ShimmerContentBlock,
  ShimmerCategoryItem,
} from "react-shimmer-effects";
import {
  patientSelector,
  setSelectedPatient,
} from "../../store/slice/patient.slice";
import ConfirmationBox from "./ConfirmationBox";
import UploadReportModal from "./UploadReport";
import RecordItem from "../../components/layout/RecordItem/RecordItem";
import {
  StyledImage,
  StyledModal,
  StyledModalContent,
  StyledCloseButton,
} from "./style.components";
import StatusDropdown from "../shared/StatusDropdown";
import AddIcon from "@mui/icons-material/Add";
import AboutInfo from "../AboutInfo";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.6rem;
  font-color: "#000";
  margin-top: 1rem;
`;

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const InfoSection = () => {
  const [dicomInfo, setDicomInfo] = useState(null);
  const [extraDicomInfo,setExtraDicomInfo]= useState(null)
  const [loading, setLoading] = useState(false);
  const [loadingAttachedReport, setLoadingAttachedReport] = useState(false);
  const [loadingScanDetails, setLoadingScanDetails] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
 const { studyFhirId,request_id, performer_info, comments, request_date, status,urgency } = location.state || {};
  const navigate = useNavigate();
  const [scans, setScans] = useState([]);
  const [attachedReport, setAttachedReport] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [storeData, setStoreData] = useState(null);
  const [issStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const [isShowChangeStatus, setShowChangeStatus] = useState(false);
  const [isAddedNewRecords, setIsAddedNewRecords] = useState(false);

  const { selectedPatientId, mobileNumber, firstName, gender, dob } =
    useSelector(patientSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (studyFhirId) {
      // Perform necessary operations with the data
      setExtraDicomInfo({
        request_id,
        performer_info,
        comments,
        request_date,
        status,
        urgency
      });
    }
  }, [studyFhirId,request_id, performer_info, comments, request_date, status,urgency]);

  console.log("extraDicomInfo1",extraDicomInfo)

  const handleOpenModal = () => {
    // setIsModalOpen(true);
    navigate('add-records', { state: {studyFhirId: studyFhirId}});
  };

  const handleOpenModalExtra = () => {
    console.log("hhj")
    navigate('add-records', { state: {studyFhirId: studyFhirId,extraDicomInfo:extraDicomInfo}});
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchStudyDetails = async () => {
    setLoading(true);
    try {
      const action = await dispatch(getStudyInfo({ fhir_id: studyFhirId }));
      const response = action.payload;
      if (response && response.status === "success") {
        const responseData = action?.payload?.data || [];
        setDicomInfo(responseData);
      } else {
        console.error("Failed to fetch study info:", action.error);
      }
    } catch (error) {
      console.error("Failed to fetch study info:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchScanDetails = async () => {
    setLoadingScanDetails(true);
    try {
      const scansAction = await dispatch(
        getScansDetails({ fhir_id: studyFhirId })
      );
      const response = scansAction.payload;
      if (scansAction && response.status === "success") {
        const responseScansData = scansAction?.payload?.data || [];
        setScans(responseScansData);
      } else {
        console.error("Failed to fetch study info:", scansAction.error);
      }
    } catch (error) {
      console.error("Failed to fetch study info:", error);
    } finally {
      setLoadingScanDetails(false);
    }
  };

  const fetchAttachedReport = async () => {
    setLoadingAttachedReport(true);
    try {
      const attachedReports = await dispatch(
        getAttachedReport({
          fhir_id: studyFhirId,
          patient_id: selectedPatientId,
        })
      );
      const response = attachedReports.payload;
      if (response && response.status === "success") {
        const responseScansData = response?.data || [];
        setAttachedReport(responseScansData);
        fetchThubnailImages(responseScansData);
      } else {
        console.error("Failed to fetching attached report:", response.error);
      }
    } catch (error) {
      console.error("Failed to fetch attached report:", error);
    } finally {
      setLoadingAttachedReport(false);
    }
  };

  const fetchThubnailImages = async (responseScansData) => {
    if (!responseScansData?.length) return;
    const filePaths = responseScansData
      .filter((item) => item?.file_path)
      .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnails({
          file_objects: filePaths,
        })
      );
  };

  useEffect(() => {
    if(studyFhirId) {
      fetchStudyDetails();
      fetchScanDetails();
    }
  }, []);

  useEffect(() => {
    if(studyFhirId)
      fetchAttachedReport();
  }, [isAddedNewRecords]);

  useEffect(() => {
    if(!studyFhirId && selectedPatientId) {
      navigate(`/patient-records?patient_id=${selectedPatientId}`)
    }
  }, [])

  const handleBackNavigation = (event) => {
    event.preventDefault();
    navigate(-1);
    dispatch(
      setSelectedPatient({
        id: selectedPatientId,
        phone_number: mobileNumber,
        first_name: firstName,
        gender: gender,
        dob: dob,
      })
    );
  };

  const onStatusAvailable = async (status) => {
    if (status) {
      setIsStatusChangeLoading(true);
      try {
        const payload = {
          record_type: "report",
          id: storeData?.id,
          status: status,
        };
        const response = await dispatch(
          changeStatusRestrictedToAvailableAction({ payload: payload })
        );
        if (response && response?.payload?.status === "success") {
          toast.success(
            response?.payload?.message,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
            500
          );
          const attachedReports = attachedReport.map((item) => {
            return item.id !== storeData?.id
              ? item
              : { ...item, status: status };
          });
          setAttachedReport([...attachedReports]);
        } else {
          alert("Something went wrong!");
        }
        setShowChangeStatus(false);
        setIsStatusChangeLoading(false);
      } catch (err) {
        console.log(err);
        setShowChangeStatus(false);
        setIsStatusChangeLoading(false);
        alert("Something went wrong!");
      }
    } else {
      setShowChangeStatus(false);
      setIsStatusChangeLoading(false);
    }
  };

  const breadCrubLinksTele = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Tele-radiology",
      onClick: () =>
        navigate(`/tele-radiology`),
    },
    {
      label: "Scan",
    },
  ];
  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const breadCrubLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: "Health Data",
      href: `/patient-records?patient_id=${selectedPatientId}`,
    },
    {
      label: "Scan",
    },
  ];

  const handleChange = (e, item) => {
    if (e.target.value !== item?.status) {
      setShowChangeStatus(true);
      setStoreData({ ...item, status: e.target.value });
    }
  };

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      if (response?.payload?.status === "success") {
        return response?.payload?.data;
      } else {
        return "";
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  const handleListItemClick = async (e, record) => {
    e.stopPropagation();
    const url = await fetchReportUrl(record?.file_path);
    url && setSelectedRecord({ ...record, url: url });
    setOpen(true);
  };

  const renderAttachedReport = () => (
    <>
      {!loadingAttachedReport ? (
        attachedReport.length ? (
          <Grid container gap={3} mt={4} >
            {attachedReport.map((item, index) => {
              return (
                <Box
                  style={{ width: 140 , color: "#000"}}
                  display="flex"
                  flexDirection="column"
                >
                  <RecordItem
                    reportType={"report"}
                    fetchUrlForFile={(e) => handleListItemClick(e, item)}
                    setStoreData={setStoreData}
                    menuItems={[]}
                    row={item}
                    index={index}
                    thumbnailData={thumbnails}
                    isNotShowDropdown={true}
                    isShowDropdown={true}
                    width={135}
                  />
                  <StatusDropdown
                    handleChange={(e) => handleChange(e, item)}
                    selectedStatus={item?.status}
                    dropdownOptions={dropdownOptions}
                    showInfoText={true}
                  />
                </Box>
              );
            })}
          </Grid>
        ) : (
          <Box
            mt={6}
            mb={2}
            display={"flex"}
            alignItems="center"
            sx={{ color: "#000", fontSize: "13px" }}
            justifyContent="center"
          >
            Records not Attached.{" "}
          </Box>
        )
      ) : (
        <Box m={2}>
          <ShimmerContentBlock
            title
            text
            cta
            thumbnailWidth={100}
            thumbnailHeight={100}
          />
        </Box>
      )}
    </>
  );

  return (
    <>
      <Box m={5} >
        <Breadcrumb links={location.pathname==="/tele-radiology/scan-info" ? breadCrubLinksTele : breadCrubLinks} />
       
        <TopHeader>Studies</TopHeader>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="stretch"
        >
          {location.pathname==="/tele-radiology/scan-info" && extraDicomInfo ? (
  <Grid item>
    <AboutInfo
      recordInfo={extraDicomInfo}
      scans={scans}
      isLoding={loading}
      sx={{}}
    />
  </Grid>
) : null}
          <Grid item>
            <Paper
              className="box-wrapper"
              sx={{
                background: "#EBF7FB",
                borderRadius: {
                  xs: "1rem 1rem 0 0",
                  md: "1rem 0 0 1rem",
                },
                paddingBottom: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                className="info-header"
                sx={{
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: { xs: "1rem", sm: 0 },
                }}
              >
                <Typography variant="h6" sx={{ color: "#FFF" }}>
                  {removeSpecialCharacters(dicomInfo?.study_info?.patient_name)}
                </Typography>
                <Button
                  sx={{
                    color: "#FFF",
                    border: "1px solid #FFF",
                    borderRadius: "15px",
                    padding: "5px 10px",
                  }}
                  onClick={()=>{!extraDicomInfo ? handleOpenModal() : handleOpenModalExtra()}}
                >
                  <AddIcon sx={{ color: "#FFF", marginRight: 1 }} /> Add Record
                </Button>
              </Box>
              <Box>
                {!loading ? (
                  dicomInfo ? (
                    <FileInfoSection recordInfo={dicomInfo?.study_info} />
                  ) : null
                ) : (
                  <Box
                    m={2}
                    sx={{
                      padding: "1rem",
                      borderRadius: "1rem",
                      background: "#FFF",
                    }}
                  >
                    <ShimmerCategoryItem
                      hasImage
                      imageType="circular"
                      imageWidth={100}
                      imageHeight={80}
                      title
                    />
                  </Box>
                )}

                {attachedReport.length ? (
                  <Box
                    display="flex"
                    m={2}
                    p={2}
                    flexDirection="column"
                    sx={{ background: "#FFF", borderRadius: 4 }}
                  >
                    <Box className="series-text" pl={2}>
                      Attached Report{" "}
                      {attachedReport.length
                        ? `(${attachedReport.length})`
                        : ""}
                      {renderAttachedReport()}
                    </Box>
                  </Box>
                ) : null}
                <Box
                  display="flex"
                  m={2}
                  p={2}
                  flexDirection="column"
                  sx={{ background: "#FFF", borderRadius: 4 }}
                >
                  <Box className="series-text" pl={2}>
                    Series {scans.length ? `(${scans.length})` : ""}
                  </Box>
                  <Box display="flex">
                    {!loadingScanDetails ? (
                      <DisplaySeries recordInfo={scans} />
                    ) : (
                      <Box m={2}>
                        <ShimmerContentBlock
                          title
                          text
                          cta
                          thumbnailWidth={400}
                          thumbnailHeight={200}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <UploadReportModal
                  open={isModalOpen}
                  handleClose={handleCloseModal}
                  studyFhirId={studyFhirId}
                  setIsAddedNewRecords={setIsAddedNewRecords}
                  isAddedNewRecords={isAddedNewRecords}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item>
            <MetaInfo
              recordInfo={dicomInfo}
              scans={scans}
              isLoding={loading}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Box>
        <StyledModal open={open} onClose={() => {}}>
          <StyledModalContent>
            <StyledCloseButton onClick={() => setOpen(false)}>
              &times;
            </StyledCloseButton>
            {selectedRecord &&
              (["image/jpeg", "image/png"].includes(
                selectedRecord.content_type
              ) || selectedRecord?.record_type === "image" ? (
                <StyledImage
                  src={selectedRecord?.url}
                  alt={selectedRecord?.name}
                />
              ) : (
                <Worker workerUrl={workerUrl}>
                  <Viewer fileUrl={selectedRecord?.url} />
                </Worker>
              ))}
          </StyledModalContent>
        </StyledModal>

        {isShowChangeStatus ? (
          <ConfirmationBox
            title={"Are you Sure?"}
            description={
              storeData?.status === "RESTRICTED"
                ? "You are changing the report sharing status. The patient will lose access to the file."
                : "You are changing the report sharing status. The patient will get access to the report and will be notified."
            }
            closeHandler={() => {
              setIsStatusChangeLoading(false);
              setShowChangeStatus(false)
            }}
            open={isShowChangeStatus}
            onConfirm={onStatusAvailable}
            isLoding={issStatusChangeLoading}
            status={storeData?.status}
          />
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default InfoSection;
