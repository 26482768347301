import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const addPatient = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.PATIENT, data, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const fetchTeleRadiologyData = async (page) => {
  try {
    const url = `${API_ENDPOINT.FETCH_TELERADIOLOGYDATA}?${page}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

 const getTeleRadiologyData = async (request_id,payload) => {
  try {
    const url = `${API_ENDPOINT.GET_TELERADIOLOGYDATA}/${request_id}`;
    const response = await apiClient.put(url,payload);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};


export {
  getTeleRadiologyData,
  fetchTeleRadiologyData
};
