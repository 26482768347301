import { Paper, Typography, Box } from "@mui/material";
import styled from "styled-components";
import LabInfo from "./LabInfo";
import '../MetaInfo/MetaInfo.css'

const HrLine = styled.div`
  border: 1px solid #c5dde0;
  margin: 1rem 0;
`;

const Color = {
  color: { xs: "#0099CC", sm: "#FFF" },
  fontSize: "16px",
  padding: "0.1rem",
  fontWeight: 600,
};

const AboutInfo = ({
  header = "Request Details",
  recordInfo,
  scans,
  isLoding,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        borderTopLeftRadius:"1rem",borderTopRightRadius:"1rem",
        background: "#fff",
        paddingBottom: 2,
        marginTop: { sm: 2, xs: 0 },
        marginRight:{ sm: 3, xs: 0 },
        flexGrow: 1,
        height: "100%",
      }}
    >
    <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{
          borderTopLeftRadius:"1rem",borderTopRightRadius:"1rem",
          background: { xs: "#D7EFF7", sm: "#0099CC" },
          padding: "1.3rem",
        }}
        className="meta-header"
      >
        <Typography variant="h6" sx={Color}>
          {header}
        </Typography>
      </Box>
      <Box m={2} className="record-wrap">
      <LabInfo recordInfo={recordInfo}/>
      </Box>
    </Paper>
  );
};

export default AboutInfo;
